import commands from "./commands.json";

function fadeIn(element: HTMLElement, duration: number, delay: number): void {
  let opacity = 0;
  const changePerFrame = 1 / (60 * duration);

  function increase() {
    const frameId = requestAnimationFrame(increase);
    opacity += changePerFrame;
    if (opacity >= 1) {
      element.style.opacity = "1";
      cancelAnimationFrame(frameId);
    } else {
      element.style.opacity = opacity.toString();
    }
  }

  setTimeout(() => {
    requestAnimationFrame(increase);
  }, delay * 1000);
}
function handleCommand(command: string): string {
  if (command in commands) {
    return commands[command];
  }
  switch (command) {
    case "help":
      return (
        "help - Display this help text\n" +
        "about - Display information about Viorae"
      );
    case "about":
      return (
        "viorae is a creative studio focusing on human experiences. " +
        "Find us on [Instagram](https://instagram.com/vio_rae) or [Twitter](https://twitter.com/viorae_)"
      );
    default:
      return (
        "Unknown command: " + command + '. Type "help" for a list of commands.'
      );
  }
}

function addTerminalLine(str: string, initDelay: number): number {
  const terminalContent = document.querySelector(".terminal-content");
  const span = document.createElement("span");
  span.className = "tk-ocr-a-std terminal-content-line";
  let delay = initDelay;

  if (str.indexOf(">") === 0) span.classList.add("blue");

  let i = 0;
  while (i < str.length) {
    if (str[i] === "[" && str.includes(")", i)) {
      // Handle a markdown link
      const linkTextEnd = str.indexOf("]", i);
      const urlEnd = str.indexOf(")", i);
      const linkText = str.substring(i + 1, linkTextEnd);
      const url = str.substring(linkTextEnd + 2, urlEnd);

      // Create a hyperlink
      const a = document.createElement("a");
      a.href = url;
      a.textContent = linkText;
      a.style.opacity = "0";
      a.style.color = "blue";
      a.style.cursor = "pointer";
      a.target = "_blank";

      span.appendChild(a);
      for (let j = 0; j < linkText.length; j++) {
        fadeIn(a, 0.035, delay);
        delay += 0.0125;
      }

      i = urlEnd + 1;
    } else {
      // Handle a regular character
      const child = document.createElement("span");
      child.className = "terminal-content-line-letter";
      child.textContent = str.charAt(i);
      child.style.opacity = "0";

      span.appendChild(child);
      fadeIn(child, 0.035, delay);

      delay += 0.0125;
      i++;
    }
  }

  if (terminalContent) {
    terminalContent.appendChild(span);
  }
  return delay;
}

function checkInput(initial: boolean = false): void {
  const terminalInput = document.querySelector(
    ".terminal-input"
  ) as HTMLInputElement;
  let totalDelay = 0;

  if (!initial) {
    const command = terminalInput.value.trim();
    const output = handleCommand(command);

    // Show the entered command
    totalDelay = addTerminalLine("> " + command, totalDelay);

    // Show the command's output, line by line
    const lines = output.split("\n");
    for (let i = 0; i < lines.length; i++) {
      totalDelay = addTerminalLine(lines[i], totalDelay);
    }

    terminalInput.value = "";
  } else {
    // Special handling for the initial text
    const lines = terminalInput.value.split("\n");
    for (let i = 0; i < lines.length; i++) {
      totalDelay = addTerminalLine(lines[i], totalDelay);
    }
    terminalInput.value = "";
  }
}

// Call checkInput at startup with initial = true
checkInput(true);

const terminalInput = <HTMLInputElement>(
  document.querySelector(".terminal-input")
);
terminalInput.addEventListener("keydown", function (e: KeyboardEvent) {
  console.log(e.code);
  if (e.code === "Enter" || e.code === "NumpadEnter") {
    e.preventDefault(); // prevent form submission
    if (terminalInput.value.trim() !== "") {
      checkInput();
    }
  }
});

checkInput(true);
